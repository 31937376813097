// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-book-online-js": () => import("./../../src/pages/book-online.js" /* webpackChunkName: "component---src-pages-book-online-js" */),
  "component---src-pages-contact-2-js": () => import("./../../src/pages/contact2.js" /* webpackChunkName: "component---src-pages-contact-2-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newpatients-js": () => import("./../../src/pages/newpatients.js" /* webpackChunkName: "component---src-pages-newpatients-js" */),
  "component---src-pages-patients-new-patients-js": () => import("./../../src/pages/patients/new-patients.js" /* webpackChunkName: "component---src-pages-patients-new-patients-js" */),
  "component---src-pages-patients-signin-js": () => import("./../../src/pages/patients/signin.js" /* webpackChunkName: "component---src-pages-patients-signin-js" */),
  "component---src-pages-pw-forget-js": () => import("./../../src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-services-adjustive-technique-js": () => import("./../../src/pages/services/adjustive-technique.js" /* webpackChunkName: "component---src-pages-services-adjustive-technique-js" */),
  "component---src-pages-services-gait-evaluation-js": () => import("./../../src/pages/services/gait-evaluation.js" /* webpackChunkName: "component---src-pages-services-gait-evaluation-js" */),
  "component---src-pages-services-js": () => import("./../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-myofascial-release-js": () => import("./../../src/pages/services/myofascial-release.js" /* webpackChunkName: "component---src-pages-services-myofascial-release-js" */),
  "component---src-pages-services-orthotics-js": () => import("./../../src/pages/services/orthotics.js" /* webpackChunkName: "component---src-pages-services-orthotics-js" */),
  "component---src-pages-services-physiotherapy-protocols-js": () => import("./../../src/pages/services/physiotherapy-protocols.js" /* webpackChunkName: "component---src-pages-services-physiotherapy-protocols-js" */),
  "component---src-pages-signin-js": () => import("./../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-telehealth-js": () => import("./../../src/pages/telehealth.js" /* webpackChunkName: "component---src-pages-telehealth-js" */),
  "component---src-pages-testimonials-js": () => import("./../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thanks-js": () => import("./../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-treatments-js": () => import("./../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */),
  "component---src-pages-treatments-motor-vehicle-collisions-js": () => import("./../../src/pages/treatments/motor-vehicle-collisions.js" /* webpackChunkName: "component---src-pages-treatments-motor-vehicle-collisions-js" */),
  "component---src-pages-treatments-neck-and-back-pain-js": () => import("./../../src/pages/treatments/neck-and-back-pain.js" /* webpackChunkName: "component---src-pages-treatments-neck-and-back-pain-js" */),
  "component---src-pages-treatments-services-js": () => import("./../../src/pages/treatments/services.js" /* webpackChunkName: "component---src-pages-treatments-services-js" */),
  "component---src-pages-treatments-sports-injuries-js": () => import("./../../src/pages/treatments/sports-injuries.js" /* webpackChunkName: "component---src-pages-treatments-sports-injuries-js" */)
}

